import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import "./NiisHero.styles.scss";

const NiisHero = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const heroContainerClasses = classNames(
    "hero-content hero-content--bottom-left ",
    {
      large: !(currentBreakPoint === "xSmall" || currentBreakPoint === "small"),
    }
  );

  const headingClasses = classNames(
    "text-white font-weight-bold mb-0",
    {
      "display-6": !(
        currentBreakPoint === "xSmall" || currentBreakPoint === "small"
      ),
    },
    {
      "display-7":
        currentBreakPoint === "xSmall" || currentBreakPoint === "small",
    }
  );

  const captionClasses = classNames("text-white font-weight-light", {
    "font-md": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  return (
    <div className="hero niis-hero">
      {/* <video
        autoPlay
        playsInline
        muted
        loop
        className="hero-video niis-hero-video"
      >
        <source
          src={require("../../assets/hero-video.mp4").default}
          type="video/mp4"
        />
      </video> */}
      <img
        src={require("../../assets/NIET Hero.jpeg").default}
        alt="Background"
        class="position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
      />
      <div className={heroContainerClasses}>
        <h1 className={headingClasses}>
          {currentBreakPoint === "xSmall" || currentBreakPoint === "small" ? (
            <>
              NIIS Institute of <br />
              Engineering & Technology
            </>
          ) : (
            "NIIS Institute of Engineering & Technology"
          )}
        </h1>
        <p className={captionClasses}>
          Bring your natural curiosity to our gorgeous campus in Bhubaneswar,
          Odisha, where we empower exploration from the day you arrive.
        </p>
      </div>
    </div>
  );
};

export default NiisHero;
