// import React from "react";
import { Row, Col } from "react-bootstrap";
// import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import FacultyCard from "../../components/FacultyCard/FacultyCard";
import { facultyData } from "../../static-data/faculty.data";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import "./FacultyPage.scss";
import React, { useState } from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import CourseInfoResponsive from "../../components/CourseInfoResponsive/CourseInfoResponsive";
import FacultyResponsive from "../../components/FacultyResponsive/FacultyResponsive";
import CoursesResponsive from "../../components/CoursesResponsive/CoursesResponsive";
import ReactModal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    backgroundColor: "#00000073",
  },
};

const FacultyPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <PageWrapper className="mt-n6">
      <HeroSection className="bg-page-background-7">
        <HeroCaption
          title="Faculty"
          position="bottom-left"
          breadcrumb={true}
          breadcrumbPrefix="Home"
        />
      </HeroSection>

      <FacultyResponsive />

      <PageSection bgClass="light">
        <>
          <Row>
            {facultyData &&
              facultyData.map((data) => (
                <Col
                  md="6"
                  key={data.email}
                  className=" align-itmes-stretch mb-4"
                >
                  <FacultyCard {...data} />
                </Col>
              ))}
          </Row>
        </>
      </PageSection>

      <br />
      <br />

      <Row className="justify-content-center">
        <Col md="4">
          <a
            href={process.env.PUBLIC_URL + "/Faculty list.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">faculty list</span>
          </a>
        </Col>

        <Col md="4">
          <a
            href={process.env.PUBLIC_URL + "/Faculty list B-5.xlsx"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">Faculty list B-5</span>
          </a>
        </Col>
      </Row>

      <br />
      <br />
    </PageWrapper>
  );
};

export default FacultyPage;
