export const iqacFacultyData = [
  {
    SlNo: 1,
    Name: "Prof. Dr. Debabrata Rath",
    Designation: "Principal",
  },
  {
    SlNo: 2,
    Name: "Prof.. Mrutyunjaya Senapati",
    Designation: "Vice. Principal",
  },
  {
    SlNo: 3,
    Name: "Prof. Dr. Jayanta kumar Sahu",
    Designation: "HOD. Electrical$ Electronics Eng.",
  },
  {
    SlNo: 4,
    Name: "Prof. Nihar  Ranjan Panda",
    Designation: "HOD. CSE",
  },
  {
    SlNo: 5,
    Name: "Prof. Dr. Manoj Kumar Biswal",
    Designation: "HOD. Civil Eng.",
  },
  {
    SlNo: 6,
    Name: "Prof. Sampad kumar Pradhan",
    Designation: "Dept. ECE",
  },
];
