import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { publicationData } from "../../static-data/publication.data";
import { Table } from "react-bootstrap";
import { iqacFacultyData } from "../../static-data/iqacFacultyData";

const IQACContentResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  const descriptionClasses = classNames("mb-4 text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  return (
    <PageSection>
      <p className={descriptionClasses}>
        Through these initiatives, the IQAC plays a pivotal role in NIET’s
        mission to deliver quality education and equip students for successful
        careers.
      </p>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th>Name</th>
            <th>Designation</th>
          </tr>
        </thead>
        <tbody>
          {iqacFacultyData.map((val) => {
            return (
              <tr>
                <td>{val.SlNo}</td>
                <td>{val.Name}</td>
                <td>{val.Designation}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <br />
      <br />

      <h4 className="">
        UGC-Recognized Open and Distance Learning (ODL) and Online Programs:
      </h4>
      <br />
      <p className={descriptionClasses}>
        NIET is pleased to inform students that the University Grants Commission
        (UGC) has recognized a list of Higher Educational Institutions (HEIs)
        that offer Open and Distance Learning (ODL) and online programs. This
        initiative ensures that students can access quality education through
        various accredited courses. <br />
        <br />
        To enroll in these programs, students must register on the UGC-DEB web
        portal and obtain a unique DEB-ID. This new process aims to enhance
        transparency and protect students from enrolling in unrecognized
        programs. <br />
        <br />
        For a complete list of UGC-recognized universities offering ODL and
        online courses, please visit the UGC Distance Education Bureau website:
        <a
          href={"https://deb.ugc.ac.in/"}
          className=" h5 "
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="ml-2">deb.ugc.ac.in/</span>
        </a>
      </p>
    </PageSection>
  );
};

export default IQACContentResponsive;
