export const diplomaProgramData = [
  { SlNo: 1, Program: "Diploma in Civil", Intake: "90" },
  { SlNo: 2, Program: "Diploma in Mechanical", Intake: "90" },
  { SlNo: 3, Program: "Diploma in Electrical", Intake: "90" },
];

export const bTechProgramData = [
  { SlNo: 1, Program: "B Tech.  in Civil Engineering", Intake: "30" },
  { SlNo: 2, Program: "B Tech.  in Mechanical Engineering", Intake: "60" },
  { SlNo: 3, Program: "B Tech.  in Electrical Engineering", Intake: "60" },
  {
    SlNo: 4,
    Program: "B Tech.  in Electrical & Electronics Engineering",
    Intake: "60",
  },
  {
    SlNo: 5,
    Program: "B Tech.  in Electronics & Computer Engineering",
    Intake: "60",
  },
  {
    SlNo: 6,
    Program: "B Tech.  in Computer Science & Engineering",
    Intake: "120",
  },
];

export const pgProgramData = [{ SlNo: 1, Program: "MCA", Intake: "60" }];
