import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";

const ArchivesResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="Archives" />
      <Row className="justify-content-center">
        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL +
              "/archives/Academic Leadership Details.xlsx"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">Academic Leadership Details</span>
          </a>
        </Col>

        <Col md="4">
          <a
            href={process.env.PUBLIC_URL + "/archives/Accounts detail.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">Accounts Detail</span>
          </a>
        </Col>

        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL + "/archives/ACTS, STATUTES or MOA.pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">ACTS, STATUTES or MOA</span>
          </a>
        </Col>

        <br />
        <br />

        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL + "/archives/Annual Report 2023-24.pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">Annual Report 2023-24</span>
          </a>
        </Col>

        <br />
        <br />

        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL + "/archives/BOARD OF GOVERNER 2023-24.pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">BOARD OF GOVERNER 2023-24</span>
          </a>
        </Col>

        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL + "/archives/BOARD OF GOVERNER 2024-25.pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">BOARD OF GOVERNER 2024-25</span>
          </a>
        </Col>

        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL + "/archives/BOARD OF GOVERNER 2025-26.pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">BOARD OF GOVERNER 2025-26</span>
          </a>
        </Col>

        <Col md="4">
          <a
            href={process.env.PUBLIC_URL + "/archives/BPUT affiliation.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">BPUT affiliation</span>
          </a>
        </Col>

        <br />
        <br />

        <Col md="4">
          <a
            href={process.env.PUBLIC_URL + "/archives/EOA Report 2024-2025.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">EOA Report 2024-2025</span>
          </a>
        </Col>

        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL +
              "/archives/Guidelines on Public Self-Disclosures by Higher Education Institutions.pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              Guidelines on Public Self-Disclosures by HEI
            </span>
          </a>
        </Col>

        <Col md="4">
          <a
            href={process.env.PUBLIC_URL + "/archives/ICC.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">ICC</span>
          </a>
        </Col>

        <br />
        <br />

        <Col md="4">
          <a
            href={process.env.PUBLIC_URL + "/archives/IDP.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">IDP</span>
          </a>
        </Col>

        <Col md="4">
          <a
            href={process.env.PUBLIC_URL + "/archives/OMBUDSPERSON.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">OMBUDS PERSON</span>
          </a>
        </Col>

        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL + "/archives/Sponsoring Body Details.pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">Sponsoring Body Details</span>
          </a>
        </Col>
      </Row>
    </PageSection>
  );
};

export default ArchivesResponsive;
