import React from "react";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import HeroSection from "../../../components/HeroSection/HeroSection";
import HeroCaption from "../../../components/HeroCaption/HeroCaption";

import FacultyResponsive from "../../../components/FacultyResponsive/FacultyResponsive";
import CoursesResponsive from "../../../components/CoursesResponsive/CoursesResponsive";
import DepartmentInfoResponsive from "../../../components/DepartmentInfoResponsive/DepartmentInfoResponsive";
import DepartmentAboutInfoResponsive from "../../../components/DepartmentAboutInfoResponsive/DepartmentAboutInfoResponsive";
import HODResponsive from "../../../components/HODResponsive/HODResponsive";

const hodData = {
	imageUrl: "",
	designation: "Head of the Department",
	name: "Prof. Name",
	feedback: `It gives me great pleasure to welcome you to the Department of Computer Science and Engineering (CSE) at NIIS Institute of Engineering & Technology. Our department is dedicated to providing a comprehensive education in the field of Computer Science and Engineering to our students, and we are proud to offer a range of programs that cater to the needs of the industry.

    We take pride in our faculty members who are experts in their respective fields and have a passion for teaching. Our curriculum is designed to equip our students with the skills and knowledge needed to excel in the fast-paced and ever-changing world of technology.
    Thank you for visiting our website, and we look forward to your continued support.
    In addition to core courses, this department's B.Tech students have access to professional and open electives. The main goal is to gradually increase each student's knowledge base in the fundamentals of computer science, which will be followed by rigorous, in-depth instruction in their particular areas of interest. The ability to master the necessary principles and techniques for analysis, design, and experimentation in a variety of domains is supported through laboratory exercises, independent/group projects, and academic research projects. The programme facilitates placement in reputable businesses and offers opportunities for on-the-job training in projects.
    We invite you to be part of our journey as we strive to propel our department of Computer Science & Engineering to ever-greater heights.
    `,
};

const ComputerSciencePage = () => {
	return (
    <PageWrapper className="mt-n6">
      <HeroSection className="bg-page-background-7">
        <HeroCaption
          title="Computer Science & Engineering"
          position="bottom-left"
          breadcrumb={true}
          breadcrumbPrefix="Home"
        />
      </HeroSection>
      <DepartmentInfoResponsive
        title={"Computer Science"}
        desc={`The Computer Science and Engineering (CSE) Department at NIET is dedicated to providing our students with a cutting-edge education in computer science. Our department offers a range of programs and courses designed to equip our students with the skills and knowledge they need to succeed in the dynamic and rapidly evolving field of computer science.
`}
      />
      <HODResponsive hodData={hodData} />
      <DepartmentAboutInfoResponsive
        title={"About department"}
        desc={`Our CSE Department offers a B.Tech in Computer Science program that covers a wide range of topics including programming languages, data structures, algorithms, software engineering, computer architecture, databases, operating systems, and computer networks. We also offer elective courses in emerging areas such as artificial intelligence, machine learning, and data science, allowing our students to specialize in their area of interest.
                Our department is equipped with state-of-the-art facilities, including computer labs and software development tools, to support our students' practical learning experience. Our faculty members work closely with our students to provide hands-on training and real-world experience, preparing them for successful careers in the industry.
                We also encourage our students to participate in co-curricular activities such as coding competitions, hackathons, and tech conferences, providing them with opportunities to network with industry professionals and gain exposure to the latest industry trends and practices.
                At NIET's CSE Department, we are committed to providing our students with the best possible education in computer science. Our goal is to produce highly skilled and competent graduates who are well-prepared to contribute to the industry and society as a whole.
                
`}
      />
    </PageWrapper>
  );
};

export default ComputerSciencePage;
