import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
// import EnquiryFormResponsive from "../EnquiryFormResponsive/EnquiryFormResponsive";
import PageSection from "../PageSection/PageSection";
// import ContactUsForm from "../ContactUsFrom/ContactUsForm";
import { BsFillHouseFill } from "react-icons/bs";
import CustomIcon from "../../components/CustomIcon/CustomIcon";

import { BiWorld } from "react-icons/bi";
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai";
import { aboutUsCardsData } from "../../static-data/aboutus.data";
import AboutUsMiniCard from "../AboutUsMiniCard/AboutUsMiniCard";
// import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
const AboutNibaResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="About Us" />
      <p className={descriptionClasses}>
        NIIS Institute of Engineering & Technology in Bhubaneswar is dedicated
        to delivering top-notch engineering education while contributing to
        India's growth in science and technology. With a strong focus on
        rigorous academic programs, the institute promotes both fundamental and
        applied research, as well as innovation and entrepreneurship under the
        agile guidance of team of technocrats headed by Principal.
        <br />
        <br />
        The institution is established in the year 2009 under the aegis of
        Saraswata Educational Trust, affiliated to Biju Patnaik University of
        Technology (BPUT), boasts a vibrant community of esteemed faculty,
        talented students, along with an efficient administrative team. This
        collaborative environment fosters not only academic excellence but also
        a culture of creativity and progress.
      </p>
      <br />

      <Row className="mb-5">
        {aboutUsCardsData.map((data, index) => (
          <Col
            md="3"
            className="d-flex align-items-stretch mb-4 mb-md-0"
            key={"about-us-home" + index}
          >
            <AboutUsMiniCard {...data} />
          </Col>
        ))}
      </Row>

      <Row className="justify-content-between">
        <Col md="6" className="mb-5 mb-md-0 ">
          <div
            className="border mb-4 rounded px-5 py-4 bg-light"
            style={{ height: "85%" }}
          >
            <p className="display-9 font-weight-normal font-heading-font">
              Vision
            </p>
            <p style={{ textAlign: "justify" }}>
              To become a nationally reckoning institute by developing value
              based transformational leaders who can contribute to the economic
              growth and social development of our nation.
            </p>
          </div>
        </Col>
        <Col md="6" className="mb-5 mb-md-0 ">
          <div
            className="border mb-4 rounded px-5 py-4 bg-light"
            style={{ height: "85%" }}
          >
            <p className="display-9 font-weight-normal font-heading-font">
              Mission
            </p>
            <p style={{ textAlign: "justify" }}>
              Our mission is to foster knowledge, wisdom, skill and competence
              among new age young minds to face the adversity and challenges of
              complex business scenarios having high adversity quotient and
              positive attitude with social values and ethos.
            </p>
          </div>
        </Col>
      </Row>
    </PageSection>
  );
};

export default AboutNibaResponsive;
