import React from "react";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import HeroSection from "../../../components/HeroSection/HeroSection";
import HeroCaption from "../../../components/HeroCaption/HeroCaption";

import FacultyResponsive from "../../../components/FacultyResponsive/FacultyResponsive";
import CoursesResponsive from "../../../components/CoursesResponsive/CoursesResponsive";
import DepartmentInfoResponsive from "../../../components/DepartmentInfoResponsive/DepartmentInfoResponsive";
import DepartmentAboutInfoResponsive from "../../../components/DepartmentAboutInfoResponsive/DepartmentAboutInfoResponsive";
import HODResponsive from "../../../components/HODResponsive/HODResponsive";

import { civilLessonPlan } from "../../../static-data/courselessonplan.data";
import { civilMaterials } from "../../../static-data/coursematerial.data";
import ResourcesResponsive from "../../../components/ResourcesResponsive/ResourcesResponsive";
import CivilHOD from "../../../assets/hod-images/civil.png"

const hodData = {
	imageUrl: require("../../../assets/hod-images/civil.png"),
	designation: "Head of the Department",
	name: "Amit Saumendra Sahoo",
	feedback: `Dear Students,
	As the Head of the Civil Engineering Department at NIET, I am pleased to welcome you to our department. Our department is dedicated to providing you with a comprehensive education in civil engineering that prepares you for a successful career in the industry.
	Our faculty members are experts in their respective fields and are committed to providing you with a challenging and rigorous curriculum that covers a wide range of topics, including structures, construction, transportation, water resources, and environmental engineering.
	Our department is equipped with state-of-the-art facilities, including civil engineering labs, simulation tools, and equipment, to support your practical learning experience. We also provide opportunities for you to work on projects and participate in research programs to gain hands-on experience.
	We encourage you to participate in co-curricular activities such as technical paper presentations, project exhibitions, and industry collaborations, which provide you with opportunities to showcase your skills and talents and network with industry professionals.
	Our goal at NIET's Civil Engineering Department is to produce graduates who are well-prepared to contribute to the industry and society as a whole. We are committed to providing you with the best possible education in civil engineering and equipping you with the skills you need to succeed in your chosen career paths.
	I look forward to working with all of you and wish you the best of luck in your academic pursuits.
	Sincerely,
	
    `,
};

const civilSyllabus = "https://drive.google.com/drive/folders/16zOXPUiWfUs8mwTmpqWI3obPnJayil1G?usp=drive_link";

const CivilPage = () => {
	return (
    <PageWrapper className="mt-n6">
      <HeroSection className="bg-page-background-7">
        <HeroCaption
          title="Civil"
          position="bottom-left"
          breadcrumb={true}
          breadcrumbPrefix="Home"
        />
      </HeroSection>
      <DepartmentInfoResponsive
        title={"Civil Engineering"}
        desc={`The Civil Engineering Department at NIET is committed to providing students with a comprehensive education in civil engineering that prepares them for successful careers in the industry.
`}
      />
      <HODResponsive hodData={hodData} />
      <DepartmentAboutInfoResponsive
        title={"About department"}
        desc={`The Civil Engineering Department at NIET is committed to providing students with a comprehensive education in civil engineering that prepares them for successful careers in the industry.
				Our department offers a B.Tech in Civil Engineering program that covers both core and elective courses, allowing students to specialize in their area of interest. The curriculum covers a wide range of topics, including structures, construction, transportation, water resources, and environmental engineering.
				Our faculty members are experts in their respective fields and are dedicated to providing students with a challenging and rigorous curriculum. We also provide opportunities for students to work on projects and participate in research programs to gain hands-on experience.
				The department is equipped with state-of-the-art facilities, including civil engineering labs, simulation tools, and equipment, to support practical learning experiences. We also encourage students to participate in co-curricular activities such as technical paper presentations, project exhibitions, and industry collaborations, which provide them with opportunities to showcase their skills and talents and network with industry professionals.
				Our goal at NIET's Civil Engineering Department is to produce graduates who are well-prepared to contribute to the industry and society as a whole. We are committed to providing our students with the best possible education in civil engineering and equipping them with the skills they need to succeed in their chosen career paths.`}
      />
      <ResourcesResponsive
        syllabusLink={civilSyllabus}
        courseLessonPlan={civilLessonPlan}
        courseMaterial={civilMaterials}
      />
    </PageWrapper>
  );
};

export default CivilPage;
