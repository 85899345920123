import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";

const ResearchInfoResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="Research" />
      <p className={descriptionClasses}>
        NIET is dedicated to fostering an expanding research enterprise that
        produces new knowledge, strengthens existing scholarship, and discovers
        solutions with the potential to benefit society. Our researchers
        collaborate with academics, industry experts, and scientists from around
        the globe to promote advances in science and technology. With
        state-of-the-art infrastructure, interdisciplinary research groups, and
        strong partnerships, we have created a vibrant research ecosystem that
        encourages innovation and impactful discoveries. <br />
        <br />
        The Research Center at NIET is dedicated to fostering a thriving
        research community by providing high-quality facilities and
        comprehensive support services. Our center is home to strong research
        groups specializing in critical areas such as data science, machine
        learning, artificial intelligence, optical sensors, power electronics
        and renewable energy systems, signal processing, VLSI, and wireless
        sensor networks. These groups are committed to advancing knowledge and
        driving innovation, making significant contributions to their respective
        fields and addressing real-world challenges.
      </p>

      <Row className="justify-content-center">
        <Col md="4">
          <a
            href={process.env.PUBLIC_URL + "/RESEARCHPOLICY.docx"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">DOWNLOAD RESEARCH POLICY</span>
          </a>
        </Col>

        <Col md="4">
          <a
            href={process.env.PUBLIC_URL + "/MOUs.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">MOUs</span>
          </a>
        </Col>
      </Row>
    </PageSection>
  );
};

export default ResearchInfoResponsive;
