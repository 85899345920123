import React from "react";
import { Image } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { publicationData } from "../../static-data/publication.data";
import { publicationData2 } from "../../static-data/publication.data";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
const PublicationResponsive = () => {
  return (
    <>
      <PageSection bgClass="bg-10">
        <ResponsivePageHeader centered={true} title="Publications" />

        <h4 className="">Journals :</h4>

        <div className="ml-5 mt-3">
          <ol type="1">
            <li>
              <p>
                Debabrata Rath et. al., “Breaking Boundaries: Optimizing Dry
                Machining for AISI D4 Hardened Tool Steel throughHybrid Ceramic
                Tool Inserts”, Proc. ImechE Part E: J of Process Mechanical
                Engineering, Accepted (July2024)doi:10.1177/09544089241265036
                (SCI Journal).
              </p>
            </li>
            <li>
              <p>
                Debabrata Rath et. al., “On Statistical process control study
                for front door outer panel of a passenger vehicle through
                process improvement”. Int J Interact Des. Manuf., 17, 1399-1407,
                2023.https://doi.org/10.1007/s12008-022-01178-4 (SCI Journal).
              </p>
            </li>
            <li>
              <p>
                Debabrata Rath et. al., “An insight into ultrasonic vibration
                assisted conventional manufacturing processes: A comprehensive
                review.” Advances in Mechanical Engineering, 14(6), pp.1-21,
                2022.doi:10.1177/16878132221107812 (SCI Journal)
              </p>
            </li>
            <li>
              <p>
                Debabrata Rath et. al., “Analysis and prediction of tool wear in
                dry turning of hardened D3 steel using hybrid insert: A novel
                wear map approach.” ProcImechE Part B: J of Engineering
                Manufacture, 236(10), pp.1355-1367, 2022.
                https://doi.org/10.1177/09544054221076242. (SCI Journal)
              </p>
            </li>
            <li>
              <p>
                Debabrata Rath et. al., “Performance Analysis of Hybrid Ceramic
                Insert in Dry Turning of Hardened Tool Steel”, Arab J SciEng,
                47(12), pp.15455-15476, 2022.
                https://doi.org/10.1007/s13369-022-06639-2(SCI Journal).
              </p>
            </li>
            <li>
              <p>
                Debabrata Rath et. al., “Dry turning of AISI D3 steel using a
                mixed ceramic insert: A study.” Proc. IMechE Part C: J
                Mechanical Engineering Science, 233(19–20), pp.6698–6712,
                2019.https://doi.org/10.1177/0954406219862844 (SCI Journal).
              </p>
            </li>
            <li>
              <p>
                Debabrata Rath et. al., “Particle Swarm Optimization and
                Machinability Aspects during Turning of Hardened D3 Steel.”
                Journal of Advanced Manufacturing Systems,19(04), pp.641-662,
                2020. https://doi.org/10.1142/S021968672050033X(SCI Journal).
              </p>
            </li>
            <li>
              <p>
                Chattaraj, U., Seyfried, A., Chakroborty, P., “Biswal, M.K.
                (2013) “Modelling Single File Pedestrian Motion Across
                Cultures.” Procedia - Social and Behavioral Sciences,
                ElsevierB.V. 104, pp. 698 – 707,), (SCI Journal).
              </p>
            </li>
            <li>
              <p>
                Jayanta Kumar Sahu, Babita Panda, Sudhakar Sahu, “ Study of
                Cuckoo Search MPPT Algorithm for Standalone photo voltaic
                system”, international Journal of Applied Power Engineering
                (IJAPE)”, Vol. 13, No. 3, September 2024, pp. 546~553, ISSN:
                2252-8792, DOI: 10.11591/ijape.v13.i3.pp546-553. (Scopus
                Indexed)
              </p>
            </li>
            <li>
              <p>
                Jayanta Kumar Sahu, Babita Panda, “Artificial neural network for
                maximum power point tracking used in solar photovoltaic system”,
                International Journal of Power Electronics and Drive
                Systems,vol.14,no.3,pp.1694-1701,2023, doi:
                10.11591/ijpeds.v14.i3.pp1694-1701.(Scopus Indexed)
              </p>
            </li>
            <li>
              <p>
                Debabrata Rath et. al., “WEDM process optimization for machining
                characteristics of AISI 52100 grade alloy steel”. Advances in
                Materials Science and Engineering, volume 2022, Article ID
                6316799, 12 pages, 2022. https://doi.org/10.1155/2022/6316799
                (SCOPUS indexed Journal).
              </p>
            </li>
            <li>
              <p>
                Debabrata Rath et. al., “Prediction Of Surface Quality Using
                Chip Morphology with Nodal Temperature Signatures In Hard
                Turning Of AISI D3 Steel”, Materials today: Proceedings, Vol. 5,
                Issue 5, Part 2, pp.12368-12375, 2018.
                https://doi.org/10.1016/j.matpr.2018.02.215 (SCOPUS indexed
                Journal).
              </p>
            </li>
            <li>
              <p>
                Drishana Jhunjhunwalla, Debani Prasad Mishra, Jayanta Kumar
                Sahu, Dashmat Hembram and Surender Reddy Salkuti, “Satellite
                mission to study the Van Allen belts,” Indonesian Journal of
                Electrical Engineering and Computer Science; Vol. 31, No. 3,
                September 2023, pp. 1400~1407, DOI:
                10.11591/ijeecs.v31.i3.pp1400-1407.(Scopus Indexed)
              </p>
            </li>
            <li>
              <p>
                Debani Prasad Mishra, Rudranarayan Senapati, Arun Kumar Sahoo,
                Jayanta Kumar Sahu and Surender Reddy Salkuti, “Distribution
                networks power loss allocation with various power factors,”
                Indonesian Journal of Electrical Engineering and Computer
                Science; Vol. 31, No. 3, September 2023, pp. 1234~1241, DOI:
                10.11591/ijeecs.v31.i3.pp1234-1241.(Scopus Indexed)
              </p>
            </li>
            <li>
              <p>
                Jayanta Kumar Sahu, Babita Panda and Jyoti Prasad
                Patra,“Standalone PV System Integrated with DC-DC Converter
                using Fuzzy Controlled MPPT,” Published by National Cave
                Research and Protection Organization, India, Ambient Science,
                2022: Vol. 09(2); 51-54 DOI:10.21276/ambi.2022.09.2.ta03.(web of
                science Indexed)
              </p>
            </li>
            <li>
              <p>
                Mishra, S.K, Dash, P.K, Jena, S.P, “Performance and engine
                exhaust study of a CI engine in dual fuel mode using diethyl
                ether as cetane enhancer additive” International Journal of
                Advanced Technology and Engineering Exploration, 2023 10(104),
                pp. 826–839.(Scopus Indexed)
              </p>
            </li>
            <li>
              <p>
                S. K Mishra, P. Pradhan, S. P Jena “Performance and combustion
                study of a low heat rejection engine running with biogas diethyl
                ether-diesel” Waste Disposal & Sustainable Energy, 2023. (Scopus
                Indexed)
              </p>
            </li>
            <li>
              <p>
                Debabrata Rath et. al., “Experimental Investigation and Analysis
                of Extrusion of Lead from Round Section through Triangular
                Section Converging Dies: As Applied to Forward Metal Extrusion”,
                IOSR- JMCE, Vol.6, Issue 3, May-June (2013), pp. 63-70.
              </p>
            </li>
            <li>
              <p>
                Debabrata Rath et. al., “Investigation of Extrusion of Lead
                experimentally from Round Section through Equilateral Triangular
                section Converging dies at different area reductions during
                Forward Metal Extrusion Process”, Research Inventy:
                International Journal of Engineering And Science,Vol.3,Issue1,
                May (2013), pp. 32-38.
              </p>
            </li>
          </ol>
        </div>

        <br />

        <h4 className="">Conferences :</h4>

        <div className="ml-5 mt-3">
          <ol type="1">
            <li>
              <p>
                Jayanta Kumar Sahu, Saroj Kumar Mishra, Swayamjyoti Satapathy
                and Sandip Ranjan Behera, “Comparison of MPPT Algorithms using
                ANN and ANFIS in a Standalone Photovoltaic System,” Proceedings
                of the Second International Conference on Automation, Computing
                and Renewable Systems (ICACRS-2023); DVD Part Number:
                CFP23CB5-DVD; ISBN: 979-8-3503- 4022-8.(Publisher:IEEE)
              </p>
            </li>
            <li>
              <p>
                Sampurna Panda, Babita Panda, Jayanta Kumar Sahu, Arjyadhara
                Pradhan, Lipika Nanda, Chitralekha Jena, “Experimental
                Evaluation of Electrical and Thermal Efficiency for Water Based
                Cooled PV Panel,” Proceedings of the 2023 IEEE 3rd International
                Conference on Smart Technologies for Power, Energy and Control
                (STPEC). (Publisher:IEEE)
              </p>
            </li>
            <li>
              <p>
                Saroj Kumar Mishra, Jayanta Kumar Sahu, & Malle
                Yamini,“Artificial Neural Network MPPT Control in Standalone
                Solar PV systems: A Comparison,” Proceedings of the Second
                International Conference on Automation, Computing and Renewable
                Systems (ICACRS-2023); DVD Part Number: CFP23CB5-DVD; ISBN:
                979-8-3503-4022-8. (Publisher:IEEE)
              </p>
            </li>
            <li>
              <p>
                J. K. Sahu, S. K. Mishra and J. P. Patra, "MPPT Extremum Seeking
                Control Algorithm for Standalone PV System," 2023 5th
                International Conference on Smart Systems and Inventive
                Technology(ICSSIT),Tirunelveli,India,2023,pp.156160,doi:10.1109/ICSSIT55814.2023.10060869.
                (Publisher:IEEE)
              </p>
            </li>
            <li>
              <p>
                S. K. Mishra, J. K. Sahu and T. M. P. C. Reddy, "MPPT for a
                Solar PV Array: ANN and P&O Comparison," 2023 IEEE 3rd
                International Conference on Technology, Engineering, Management
                for Societal impact using Marketing, Entrepreneurship and Talent
                (TEMSMET), Mysuru, India, 2023, pp. 1-6. (Publisher:IEEE)
              </p>
            </li>
            <li>
              <p>
                Sampurna Panda, Srikanta Mohapatra, Babita Panda, Jayanta Kumar
                Sahu, Rakesh Kumar, Arjyadhara Pradhan, "Integrating solar
                energy (PV systems) into existing power grids: a brief
                overview,” ICMME-2023 jointly organized by Department of
                Mechanical Engineering, GLA University, Mathura (India) and
                UFRGS, PortoAlegre (Brazil) held on 13th – 14th October 2023.
              </p>
            </li>
            <li>
              <p>
                J. K. Sahu, B. Panda, J. P. Patra and P. Sahoo, "Fuzzy Logic
                Based Maximum Power Point Tracking Design," 2022 4th
                International Conference on Smart Systems and
                InventiveTechnology(ICSSIT),Tirunelveli,India,2022,pp.0105,doi:10.1109/ICSSIT53264.2022.9716400.
                (Publisher:IEEE)
              </p>
            </li>
            <li>
              <p>
                S. K. Mishra, D. Mohanty, J. K. Sahu and S. Mohanty, "Artificial
                Neural Network Based MPPT Controller for Stand-alone Solar PV
                System," 2021 International Conference on System, Computation,
                Automation and Networking (ICSCAN), Puducherry, India, 2021, pp.
                1-6, doi: 10.1109/ICSCAN53069.2021.9526518. (Publisher:IEEE).
              </p>
            </li>
            <li>
              <p>
                J. K. Sahu, B. Panda, S. Sahu and J. P. Patra, "Harmonics
                Estimation of a Standalone PV System Integrated with a Modified
                SPWM Inverter," 2020 Third International Conference on Smart
                Systems and Inventive Technology (ICSSIT), Tirunelveli, India,
                2020, pp. 654-656, doi:10.1109/ICSSIT48917.2020.9214186.
                (Publisher:IEEE)
              </p>
            </li>
            <li>
              <p>
                J. K. Sahu, S. K. Mishra and K. Hariharan, "Harmonic Analysis of
                Three Phase Inverter by using Particle Swarm Optimization
                Technique," 2019 International Conference on Smart Systems and
                Inventive Technology (ICSSIT), Tirunelveli, India, 2019, pp.
                1158-1162, doi: 10.1109/ICSSIT46314.2019.8987755.
                (Publisher:IEEE)
              </p>
            </li>
            <li>
              <p>
                S. Sahoo, S. K. Mishra and J. K. Sahu, "Standalone PV System
                Integrated with Hysteresis Current Controlled Inverter using
                MPPT Techniques," 2019 International Conference on Communication
                and Electronics Systems (ICCES), Coimbatore, India, 2019, pp.
                2024-2029,doi:10.1109/ICCES45898.2019.9002384. (Publisher:IEEE).
              </p>
            </li>
            <li>
              <p>
                J. k. Sahu, S. Sahu, J. P. Patra, S. K. Maharana and B. Panda,
                "Harmonics analysis of a PV integrated Hysteresis current
                control inverter connected with grid and without grid," 2019
                International Conference on Smart Systems and Inventive
                Technology (ICSSIT), Tirunelveli, India, 2019, pp. 1154- 1157,
                doi: 10.1109/ICSSIT46314.2019.8987864. (Publisher:IEEE)
              </p>
            </li>
            <li>
              <p>
                Mishra S K, Pradhan P, Jena S P. “Factors Affecting
                Co-gasification of Coconut Shell and MSW in an Updraft
                Gasifier”. Springer Nature. Proceedings of Current Advances in
                Mechanical Engineering.2021, pp-251-258.
              </p>
            </li>
            <li>
              <p>
                Mishra S K, Pradhan P, Jena S P. “Biogas Production from Dried
                Banana Leaves using Cow Urine as a Biocatalyst”. Lecture notes
                in Mechanical Engg, 2023, pp.371-373
              </p>
            </li>
            <li>
              <p>
                Amaresh Mohapatra, Sanjaya Kumar Mishra, Shakti Prakash Jena,
                Premananda Pradhan, “Co-digestion of Agricultural and Plant
                Wastes and Cow Dung for Biogas Production”. Lecture notes in
                Mechanical Engg, 2023, pp. 111-121
              </p>
            </li>
            <li>
              <p>
                Dash P K, Mishra S K, Jena S P, Das H C, “Energy and Exergy
                Study of a Compression Ignition Engine running with Producer
                gas”, Lecture notes in Mechanical Engg, 2023, pp. 35-44
              </p>
            </li>
            <li>
              <p>
                Pradhan P, Mishra S K, Jena S P. “Characterization of biomasses
                suitable for gasification”, ICRAMER-21 conference held on 15th
                March 2021, SRM institute of science and technology (Accepted
                for publication in AIP Conference Proceedings).
              </p>
            </li>
            <li>
              <p>
                S K Mishra, S P Jena, S K Acharya, P Pradhan. “Performance
                Optimization of a Dual Fuel Engine with YSZ Coating by
                Grey-Taguchi Method” Materials Today: Proceedings
              </p>
            </li>
            <li>
              <p>
                S K Mishra, S P Jena, P Pradhan. “Characterization of
                biomaterials suitable for synthesis of producer gas” Material
                Today: Proceedings, ICREMERD-23 conference held on 20th to 22nd
                July, 2023, ITER, Bhubaneswar.
              </p>
            </li>
            <li>
              <p>
                Biswal, M.K., Chattaraj, U., Panda, M. “Operational Analysis of
                Pedestrian Moment at Un- signalized Midblock Crosswalk Using
                K-means Clustering.” In Proceedings of 4th Recent Advances in
                Traffic engineering (RATE 2022), Surat, India.
              </p>
            </li>
            <li>
              <p>
                Biswal, M.K., Chattaraj, U., Panda, M. “Defining Pedestrian
                Level of Service at Signalized Intersection Crosswalk.” In paper
                compendium of 6th Conference of Transportation Research Group of
                India (CTRG), Tiruchirappalli (Trichy), Tamil Nadu, India, 14th
                - 17th December, 2021.
              </p>
            </li>
            <li>
              <p>
                Biswal, M.K., Kumar, R.V., Chattaraj, U. (2017) “Empirical
                Studies of Pedestrian Behavior in Evacuation and Queue.” In
                paper compendium of 4th Conference of Transportation Research
                Group of India (CTRG), I.I.T Bombay, India, 17th - 20th
                December, 2017.
              </p>
            </li>
            <li>
              <p>
                Das, A.K., Biswal, M.K., Chattaraj, U. (2017) “Development of a
                Model for Heterogeneous Traffic Simulation.” In paper compendium
                of 4th Conference of Transportation Research Group of India
                (CTRG), I.I.T Bombay, India, 17th - 20th December, 2017.
              </p>
            </li>
            <li>
              <p>
                Biswal, M.K., Das, J.B, Chattaraj, U., (2016) “Studies on Three
                Dimensional Pedestrian Motion on Railway Foot over Bridge.” In
                paper compendium of International Conference Transportation
                Planning & Implementation Methodologies for Developing Countries
                (12th TPMDC 2016), I.I.T Bombay, Mumbai, India, 19th - 21st
                December, 2016.
              </p>
            </li>
          </ol>
        </div>

        <br />

        <h4 className="">Book / Book series/Book Chapter/ Lecture Notes :</h4>

        <div className="ml-5 mt-3">
          <ol type="1">
            <li>
              <p>
                1.Title of the Book: Machinability and Wear Mechanism Study in
                Turning of Hardened Steel Author: Dr. Debabrata Rath Publisher:
                LAMBERT Academic Publishing, ISBN: 978-620-4-74279-3 Country of
                publication: United States, Year of publication: 2022
              </p>
            </li>
            <li>
              <p>
                Debani Prasad Mishra, Jayanta Sahoo, Anubhav Prakash Gaur,
                Ishaan sahu,“Synthesizing MPPT Control and Fault Detection
                Tactics in Autonomous Photovoltaic Systems Employing Residual
                Incremental Conductance,”Soft Computing Applications in Modern
                Power and Energy Systems - Select Proceedings of EPREC 2024,
                Volume 4,Springer Nature Singapore Pte Ltd.(Accepted).
              </p>
            </li>
            <li>
              <p>
                S K Mishra, P Pradhan, S P Jena, “Influence of Producer Gas
                Up-gradation on Exhaust Emissions of a Dual Fuel Engine”,
                Springer Lecture Note.
              </p>
            </li>
          </ol>
        </div>

        <br />

        <h4 className="">Patent :</h4>

        <div className="ml-5 mt-3">
          <ol type="1">
            <li>
              <p>
                1.Granted an INDIAN PATENT (Application No: 202231032031)
                “Airbag device for Two wheeler” (Patentee: Dr. Debabrata Rath)
              </p>
            </li>
            <li>
              <p>
                Granted an INDIAN PATENT (Application No: 202331016239) “Wear
                controlled Roller chain sprocket device for two wheeler”
                (Patentee: Dr. Debabrata Rath)
              </p>
            </li>
            <li>
              <p>
                Granted an INTERNATIONAL PATENT (Application no: 2021104792) “An
                Aluminum Hybrid Metal Matrix Composite And Method of Preparation
                Thereof” (Patentee: Dr. Debabrata Rath)
              </p>
            </li>
            <li>
              <p>
                Granted an INDIAN PATENT (Application No: 353959-001) “C-45 Die
                Set for Metal Matrix Composites Product Preparation” (Patentee:
                Dr. Debabrata Rath)
              </p>
            </li>
            <li>
              <p>
                Published an INDIAN PATENT (Application No: 202331047859) “High
                Strength Double Shackle Padlock” (Patentee: Dr. Debabrata Rath)
              </p>
            </li>
            <li>
              <p>
                Published an Indian PATENT (Application number: 202431018922)
                “Multi-function wiper” (Patentee: Dr. Debabrata Rath)
              </p>
            </li>
            <li>
              <p>
                Published an Indian PATENT (Application number: 202431019382)
                “Portable and adjustable multi-function staircase” (Patentee:
                Dr. Debabrata Rath){" "}
              </p>
            </li>
            <li>
              <p>
                Dr. M. Vaigundamoorthi, Dr. V Kamalakar, Narender Chinthamu, Mr.
                Jayanta Kumar Sahu, R.Kavin M.E, Mr. Y.Sri Chakrapani, Dr. Ch
                Manohar Kumar, Dr. Lalchand Patle, Mrs. Shaik Salma Begum and
                ManideepKarukuri, "IOT-based photovoltaic solar power generation
                with maximum power point tracking,” Application Number:
                202341026559, Publication Date 26/05/2023,Office of the
                Controller General of Patents, Designs and Trade Marks;
                Department of Industrial Policy & Promotion, Ministry of
                Commerce & Industry, Government of
                India;https://ipindia.gov.in/index.htm.
              </p>
            </li>
            <li>
              <p>
                Dr. Vijay kumar Sidramappa Biradar, Dr. Prakash Mathialagan, Dr.
                Pydimarri Padmaja, Guddeti Jagadeeswar Reddy, Dr. Srinivasa Rao
                Balasani, Dr. Dokku Siva Naga Malleswara Rao, Mr. Jayanta Kumar
                Sahu, Dr. Prasun Chakrabarti, "Hybrid Solar And Wind Powered
                Charging Station For EVehicle,” Design number:
                6330587,Publication date 13 Dec 2023, UK Design Grant
                Patent,Publication URL :
                https://www.registered-design.service.gov.uk/find.
              </p>
            </li>
            <li>
              <p>
                Mrs. Vanitha Gurugubelli, Rajendra V. Patil, Dr Rajesh Kedarnath
                Navandar, . Mr. Pechetti Girish,Dr D S Naga Malleswara Rao, Mr.
                Ramesh Pattnaik, Mr. Jayanta Kumar Sahu, Mr.Piyush Patil & Dr.
                Arun K K, "smart grid optimization through iot-enabled energy
                management,” Application Number: 202441008173, Publication
                Date:08/03/2024,Office of the Controller General of Patents,
                Designs and Trade Marks; Department of Industrial Policy &
                Promotion, Ministry of Commerce & Industry, Government of India;
                https://ipindia.gov.in/index.htm.
              </p>
            </li>
          </ol>
        </div>

        {/* <Table striped bordered hover>
					<thead>
						<tr>
							<th>Sl. No.</th>
							<th>Name of the teacher</th>
							<th>Title of the book/chapters published</th>
							<th>National/ International</th>
							<th>Year of publication</th>
							<th>ISBN number of the proceeding</th>
							<th>Affiliating Institute at the time of publication </th>
							<th>Name of the publisher</th>
						</tr>
					</thead>
					<tbody>
						{publicationData.map((val) => {
							return (
								<tr>
									<td>{val.SlNo}</td>
									<td>{val.Nameoftheteacher}</td>
									<td>{val.Titleofthebook}</td>
									<td>{val.type}</td>
									<td>{val.Yearofpublication}</td>
									<td>{val.ISBNnumber}</td>
									<td>{val.Affiliating}</td>
									<td>{val.Nameofthepublisher}</td>
								</tr>
							);
						})}
					</tbody>
				</Table> */}
      </PageSection>
    </>
  );
};

export default PublicationResponsive;
