import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import GrievanceCellResponsive from "../../components/GrievanceCellResponsive/GrievanceCellResponsive";
import GuidelineResponsive from "../../components/GuidelineResponsive/GuidelineResponsive";
import GuidelineContentResponsive from "../../components/GuidelineContentResponsive/GuidelineContentResponsive";
import IQACResponsive from "../../components/IQACResponsive/IQACResponsive";
import IQACContentResponsive from "../../components/IQACContentResponsive/IQACContentResponsive";

const IQACPage = () => {
  return (
    <PageWrapper className="mt-n6">
      <HeroSection className="bg-page-background-4">
        <HeroCaption
          title="IQAC"
          position="bottom-left"
          breadcrumb={true}
          breadcrumbPrefix="Home"
        />
      </HeroSection>
      <IQACResponsive/>
      <IQACContentResponsive/>
    </PageWrapper>
  );
};

export default IQACPage;
