import React from "react";
import { Route, Switch } from "react-router-dom";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import CustomNavbar from "./components/CustomNavbar/CustomNavbar";
import CustomFooter from "./components/CustomFooter/CustomFooter";
import FourOFour from "./pages/FourOFour/FourOFourPage";
import MissionAndVisionPage from "./pages/MissionAndVisionPage/MissionAndVisionPage";
import DirectorsMessagePage from "./pages/DirectorsMessagePage/DirectorsMessagePage";
import AcademicAdvisorPage from "./pages/AcademicAdvisorPage/AcademicAdvisorPage";
import AdmissionEnquiryPage from "./pages/AdmissionEnquiryPage/AdmissionEnquiryPage";
import SoftSkillLabPage from "./pages/SoftSkillLabPage/SoftSkillLabPage";
import ClassRoomPage from "./pages/ClassRoomPage/ClassRoomPage";
import HomePage from "./pages/HomePage/HomePage";
import ConferenceHallPage from "./pages/ConferenceHallPage/ConferenceHallPage";
import SeminarHallPage from "./pages/SeminarHallPage/SeminarHallPage";
import TransportationPage from "./pages/TransportationPage/TransportationPage";
import LibraryPage from "./pages/LibraryPage/LibraryPage";
import ComputerLabPage from "./pages/ComputerLabPage/ComputerLabPage";
import HostelPage from "./pages/HostelPage/HostelPage";
import CanteenPage from "./pages/CanteenPage/CanteenPage";
import CommunityServicePage from "./pages/CommunityServicePage/CommunityServicePage";
import OtherFacilityPage from "./pages/OtherFacilityPage/OtherFacilityPage";
import OnlineExamPage from "./pages/OnlineExamPage/OnlineExamPage";
import ContactUsPage from "./pages/ContactUsPage/ContactUsPage";
import FacultyPage from "./pages/FacultyPage/FacultyPage";
import ResearchPage from "./pages/ResearchPage/ResearchPage";
import AdmissionProcedurePage from "./pages/AdmissionProcedurePage/AdmissionProcedurePage";
import MBACoursePage from "./pages/MBACoursePage/MBACoursePage";
import MCACoursePage from "./pages/MCACoursePage/MCACoursePage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import AlumniPage from "./pages/AlumniPage/AlumniPage";
import GrievanceCellPage from "./pages/GrievanceCellPage/GrievanceCellPage";
import AffiliationsPage from "./pages/AffiliationsPage/AffiliationsPage";

import CoursesPage from "./pages/CoursesPage/CoursesPage";
import PlacementAboutUsPage from "./pages/PlacementAboutUsPage/PlacementAboutUsPage";
import ProjectAndInternshipPage from "./pages/ProjectAndInternshipPage/ProjectAndInternshipPage";
import SeminarPage from "./pages/SeminarPage/SeminarPage";
import FacultyDevelopmentProgramPage from "./pages/FacultyDevelopmentProgramPage/FacultyDevelopmentProgramPage";
import CampusCommunityServicePage from "./pages/CampusCommunityServicePage/CampusCommunityServicePage";
import GuidelinesPage from "./pages/GuidelinesPage/GuidelinesPage";
import AntiRaggingPolicyPage from "./pages/AntiRaggingPolicyPage/AntiRaggingPolicyPage";
import StudentCellPage from "./pages/StudentCellPage/StudentCellPage";
import InfrastructurePage from "./pages/InfrastructurePage/InfrastructurePage";
import SkillDevelopmentCenterPage from "./pages/SkillDevelopmentCenterPage/SkillDevelopmentCenterPage";
import RecruitersAndPlacementPage from "./pages/RecruitersAndPlacementPage/RecruitersAndPlacementPage";
import IndustryInterfaceCenterPage from "./pages/IndustryInterfaceCenterPage/IndustryInterfaceCenterPage";
import DreamFestPage from "./pages/DreamFestPage/DreamFestPage";
import "./App.scss";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

import { navbarData, newLinks } from "./static-data/navbar.data";
import { footerData } from "./static-data/footer.data";
import CommerceConclavePage from "./pages/CommerceConclavePage/CommerceConclavePage";
import LibraryandResourcesPage from "./pages/LibraryandResourcesPage/LibraryandResourcesPage";
import NAACPage from "./pages/NAACPage/NAACPage";
import ComputerSciencePage from "./pages/Department/ComputerSciencePage/ComputerSciencePage";
import ElectricalElectronicsPage from "./pages/Department/Electrical&ElectronicsPage/Electrical&ElectronicsPage";
import ElectricalTelecommunicationPage from "./pages/Department/Electrical&TelecommunicationPage/Electrical&TelecommunicationPage";
import ElectricalPage from "./pages/Department/ElectricalAndElectronicsPage/ElectricalAndElectronicsPage";
import MechanicalPage from "./pages/Department/MechanicalPage/MechanicalPage";
import CivilPage from "./pages/Department/CivilPage/CivilPage";
import BasicSciencesPage from "./pages/Department/BasicSciencesPage/BasicSciencesPage";
import BTechCSEProgramPage from "./pages/BTechCSEProgramPage/BTechCSEProgramPage";
import BTechCivilProgramPage from "./pages/BTechCivilProgramPage/BTechCivilProgramPage";
import BTechEEProgramPage from "./pages/BTechEEProgramPage/BTechEEProgramPage";
import BTechEnTEProgramPage from "./pages/BTechEnTEProgramPage/BTechEnTEProgramPage";
import BTechMechProgramPage from "./pages/BTechMechProgramPage/BTechMechProgramPage";
import DiplomaProgramPage from "./pages/DiplomaProgramPage/DiplomaProgramPage";
import ElectricalAndElectronicsPage from "./pages/Department/ElectricalAndElectronicsPage/ElectricalAndElectronicsPage";
import IQACPage from "./pages/IQACPage/IQACPage";

SwiperCore.use([Autoplay, Navigation, Pagination]);

const App = () => {
  return (
    <div>
      <CustomNavbar linkList={navbarData} newLinks={newLinks} />

      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/about-us/about-niet" component={AboutUsPage} />
        <Route exact path="/about-us/alumni" component={AlumniPage} />
        <Route exact path="/naac" component={NAACPage} />
        <Route
          exact
          path="/about/mission-and-vision"
          component={MissionAndVisionPage}
        />
        <Route
          exact
          path="/about/director-message"
          component={DirectorsMessagePage}
        />
        <Route
          exact
          path="/about-us/leadership-&-management"
          component={AcademicAdvisorPage}
        />
        <Route exact path="/academics/faculty" component={FacultyPage} />
        <Route exact path="/academics/research" component={ResearchPage} />
        <Route
          exact
          path="/academics/library-resources"
          component={LibraryandResourcesPage}
        />
        <Route
          exact
          path="/academics/affiliations"
          component={AffiliationsPage}
        />
        <Route exact path="/academics/programs" component={CoursesPage} />
        <Route
          exact
          path="/academics/courses/cse"
          component={BTechCSEProgramPage}
        />
        <Route
          exact
          path="/academics/courses/ee"
          component={BTechEEProgramPage}
        />
        <Route
          exact
          path="/academics/courses/civil"
          component={BTechCivilProgramPage}
        />
        <Route
          exact
          path="/academics/courses/ente"
          component={BTechEnTEProgramPage}
        />
        <Route
          exact
          path="/academics/courses/mechanical"
          component={BTechMechProgramPage}
        />
        <Route
          exact
          path="/academics/courses/diploma"
          component={DiplomaProgramPage}
        />
        <Route exact path="/academics/courses/mba" component={MBACoursePage} />
        <Route exact path="/academics/courses/mca" component={MCACoursePage} />
        <Route
          exact
          path="/admission/admission-procedure-cum-eligibility"
          component={AdmissionProcedurePage}
        />
        <Route
          exact
          path="/admission/enquiry"
          component={AdmissionEnquiryPage}
        />
        <Route
          exact
          path="/industry-interface-centre"
          component={IndustryInterfaceCenterPage}
        />
        <Route
          exact
          path="/industry-interface-centre/about-us"
          component={PlacementAboutUsPage}
        />
        <Route
          exact
          path="/industry-interface-centre/skill-development-center"
          component={SkillDevelopmentCenterPage}
        />
        <Route
          exact
          path="/industry-interface-centre/projects-internship-program"
          component={ProjectAndInternshipPage}
        />
        <Route
          exact
          path="/industry-interface-centre/recruiters-placement-record"
          component={RecruitersAndPlacementPage}
        />
        <Route
          exact
          path="/student-corner/guidelines"
          component={GuidelinesPage}
        />
        <Route
          exact
          path="/student-corner/anti-ragging-policy"
          component={AntiRaggingPolicyPage}
        />
        <Route
          path="/student-corner/clubs-committees"
          component={StudentCellPage}
        />
        <Route
          exact
          path="/student-corner/grievance-cell"
          component={GrievanceCellPage}
        />
        <Route exact path="/student-corner/iqac" component={IQACPage} />
        {/* <Route exact path="/StudentCellPage" component={StudentCellPage} /> */}
        <Route
          exact
          path="/campus-activities/seminars"
          component={SeminarPage}
        />
        <Route
          exact
          path="/campus-activities/faculty-development-program"
          component={FacultyDevelopmentProgramPage}
        />
        <Route
          exact
          path="/campus-activities/dream-fest"
          component={DreamFestPage}
        />
        <Route
          exact
          path="/campus-activities/commerce-conclave"
          component={CommerceConclavePage}
        />
        <Route
          exact
          path="/campus-activities/csr-&-sustainability"
          component={CampusCommunityServicePage}
        />

        <Route exact path="/campus" component={InfrastructurePage} />
        <Route
          exact
          path="/infrastructure/soft-skill-lab"
          component={SoftSkillLabPage}
        />
        <Route
          exact
          path="/infrastructure/classroom"
          component={ClassRoomPage}
        />
        <Route
          exact
          path="/infrastructure/conference-hall"
          component={ConferenceHallPage}
        />
        <Route
          exact
          path="/infrastructure/seminar-hall"
          component={SeminarHallPage}
        />
        <Route
          exact
          path="/infrastructure/transport-facility"
          component={TransportationPage}
        />
        <Route exact path="/infrastructure/library" component={LibraryPage} />
        <Route
          exact
          path="/infrastructure/computer-lab"
          component={ComputerLabPage}
        />
        <Route exact path="/infrastructure/hostel" component={HostelPage} />
        <Route exact path="/infrastructure/canteen" component={CanteenPage} />
        <Route
          exact
          path="/infrastructure/community-services"
          component={CommunityServicePage}
        />
        <Route
          exact
          path="/infrastructure/other-services"
          component={OtherFacilityPage}
        />
        <Route exact path="/online-exam" component={OnlineExamPage} />
        <Route exact path="/contact-us" component={ContactUsPage} />
        <Route
          exact
          path="/departments/computer-science"
          component={ComputerSciencePage}
        />
        {/* <Route
					exact
					path="/departments/electrical-&-electronics"
					component={ElectricalElectronicsPage}
				/> */}
        <Route
          exact
          path="/departments/electrical-&-telecommunication"
          component={ElectricalTelecommunicationPage}
        />
        <Route
          exact
          path="/departments/electrical-&-electronics"
          component={ElectricalAndElectronicsPage}
        />
        <Route
          exact
          path="/departments/mechanical"
          component={MechanicalPage}
        />
        <Route exact path="/departments/civil" component={CivilPage} />
        <Route
          exact
          path="/departments/basic-sciences-&-humanities"
          component={BasicSciencesPage}
        />
        <Route path="*" component={FourOFour} />
      </Switch>
      <CustomFooter linkList={footerData} />
    </div>
  );
};

export default App;
