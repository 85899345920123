import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import CustomIcon from "../CustomIcon/CustomIcon";

const AntiRagingResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  const descriptionClasses = classNames("text-center mb-4 text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  return (
    <div>
      <PageSection bgClass="background-color">
        <ResponsivePageHeader centered={true} title="Anti-Ragging Policy" />
        <p style={{ textAlign: "left" }}>
          {/* Parents put an immense trust and faith in the hands of the institute
				while sending their children to us. We always try to do right by it and
				carry concern at all times for the physical and mental stability of the
				child. We have drafted the anti-ragging policy for the same reasons-so
				that no student should be disrupted any way by another student or staff.
				We urge the students to report any unwanted activity to the management
				without any fear and they are dealt with utmost strictness and
				responsibility. */}
          Ragging or harassment of fresher’s by senior students of the Institute
          in any form is strictly prohibited. This should not be treated as a
          mere statutory warning which will invite lot of trouble for erring
          students.
        </p>
        <p>Ragging is defined as :</p>
        <ul>
          <li>
            Forcing a student to perform some action against his / her will,
          </li>
          <li>Physical violence of any kind against the student,</li>
          <li>Any kind of mental harassment of intimidation</li>
        </ul>
        <p>
          A complaint of ragging must be submitted in writing to the Principal
          or The Chairman of the Anti-Ragging Committee. · Anybody found
          indulging in the act of ragging or reported for indulging in ragging
          will be liable to severe punishment. Such students may be expelled
          from the Institute by the Principal and the case will be reported to
          the local police station for necessary action in view of the directive
          of the Honourable Supreme Court of India
        </p>
        <p>
          Ragging of any sort is strictly prohibited. As per the order of the
          Hon’ble Supreme Court of India order, ragging is a cognizable offense
          under law and may invite punishment of imprisonment. Each student has
          to sign an Affidavit in prescribed format that he/she was aware of
          penal and administrative action to be taken against them for being
          involved in ragging as per directive of Hon’ble Supreme Court of
          India.
        </p>
        {/* <p className={descriptionClasses}>
				We make sure that a student stays fit and of sound mind all the time.
			</p> */}
        <a
          href={process.env.PUBLIC_URL + "/Anti-Ragging.pdf"}
          className="d-flex align-items-center justify-content-center text-uppercase text-center text-link-color font-weight-medium font-sm font-paragraph-font"
          target="_blank"
          rel="noopener noreferrer"
        >
          <CustomIcon currentIcon={AiFillFilePdf} size="md" />
          <span className="ml-2">ANTI-RAGGING POLICY</span>
        </a>
      </PageSection>
      <PageSection bgClass="white">
        <ResponsivePageHeader centered={true} title="Anti-Ragging Action" />
        <p>
          In pursuance to the directions of the Hon&#39;ble Supreme Court in SLP
          NO.24295 of 2006 dated- 16/05/2007 and in Civil Appeal number 887 of
          2009, dated-08/05/2009 and vide AICTE Notification No-37-3/ Legal/
          AICTE/2009 dated-01/07/2009, an institute level Anti Ragging Committee
          has been constituted for the session 2024-25 for UG/PG Programmes to
          prevent and prohibit ragging in the institute premises.
          <br />
          <br />
          The Committee comprises of the following Members:-
        </p>
        <ol>
          <li>Dr. Debabrata Rath (Principal, NIET, Chhatabar) - 7205341326</li>
          <li>
            Mr. Mrutyunjay Senapati (Vice-Principal, NIET, Chhatabar) -
            7978098460
          </li>
          <li>
            Mr. Nihar Ranjan Panda (HOD, Computer Sci & Engg.) - 7606920792
          </li>
          <li>
            Ms. Dibyashree Bhuyan (Sr. Lecturer, Civil Engg.) - 8908921792
          </li>
          <li>Mr. Pramod Kumar Raj (Asst. Admin. Officer) - 7681834436</li>
          <li>Mrs. Sasmita Mishra (Librarian) - 9437757518</li>
          <li>
            Mr. Rohit Kumar Behera (Representative from press media) -
            9438621987
          </li>
          <li>
            Mr. Radhakanta Sahu (OIC, Chandaka Police Station) - 8280338323
          </li>
          <li>Mr. Sarat Kumar Sahoo (Parents representative) - 8249307167</li>
          <li>
            Ms. Priyanka Satapathy (Hostel Warden, Girls Hostel, NIET,
            Chhatabar) - 7008318835
          </li>
          <li>
            Mr. Sandeep Kumar Champatiray (Hostel Warden, Boys Hostel, NIET,
            Chhatabar) - 7008426624
          </li>
          <li>
            Mr. Somanath Jena (Hostel Warden, Boys Hostel, NIET, Chhatabar) -
            6371691874
          </li>
          <li>
            Mr. Siba Prasad Panda (3rd Semester, CSE Branch, B.TECH) -
            6294465235
          </li>
          <li>
            Mr. Abhishek Panda (B.Tech, 5th Semester, Civil Branch) - 9337635267
          </li>
          <li>
            Mr. Deepak Kumar Senapati (3rd Semester, Civil Branch, DIPLOMA) -
            8328971531
          </li>
          <li>
            Mr. Biswajit Jena (3rd Semester, Civil Branch, DIPLOMA) - 9337948525
          </li>
        </ol>
        <p>
          Any ragging incident taking place outside the institute and
          subsequently reported by an insider or outsider will also be regarded
          as ragging, and action will be taken as deemed fit. Further, it may be
          noted that a ragging incident may be reported directly to the Police
          Station or to grievance cell operating directly under the Govt. or
          Judiciary.
          <br />
          National Anti-Ragging Helpline (UGC Crisis Hotline) – 24 x 7 Toll Free
          Number* 1800-180-5522 | helpline@antiragging.in
        </p>
      </PageSection>
      <PageSection bgClass="background-color">
        <ResponsivePageHeader
          centered={true}
          title="Institute Level Anti- Ragging Squard"
        />
        <p>
          As per the directive of Hon’ble Supreme Court of India, the following
          persons are nominated as members of Anti-ragging Squad of NIIS and
          they will watch both inside and outside the institute premises
          including hostels to prevent ragging in any form and they will submit
          findings to the Chairman, Anti-ragging Committee for further action.
          In case of any harassment by senior students, the affected students of
          the junior batch may report to the squad at first instance.
        </p>
        <ol>
          <li>Dr. Manoj Ku. Biswal (HOD, Civil Engg.) - 7008354551</li>
          <li>Mr. Sanjaya Ku. Mishra (HOD, Mechanical Engg.) - 7008126758</li>
          <li>Mr. Sanjeeb Kumar Das (Lect. in Mathematics) - 9937985084</li>
          <li>Ms. Preeti Sahoo (Asst. Prof., Mechanical Engg.) - 8018559845</li>
          <li>Ms. Suprava Samal (Lect. in Civil Engg.) - 8895746263</li>
          <li>Mr. Pragati Priya Patra (Lect. in CSE) - 9556216129</li>
          <li>Mr. Sandeep Ku. Champatiray (Lect. in EE) - 7008426624</li>
          <li>Mr. Somanath Jena (Lect. in Mech) - 6371691874</li>
          <li>
            Ms. Priyanka Satapathy (Hostel Warden, Ladies Hostel, NIET,
            Chhatabar) - 7008318835
          </li>
        </ol>
        <p>
          Anti-Ragging squad will investigate incidents of Ragging within or
          outside institute campus as well as at Boys’ Hostels and Girls’
          Hostel. The report may be submitted to the Principal-Cum-Chairman of
          the Anti-ragging Committee recommending necessary action.
        </p>
      </PageSection>

      <PageSection bgClass="white">
        <ResponsivePageHeader
          centered={true}
          title="Students Grievance Redressal Committee"
        />
        <p>
          The student Grievance Committee is constituted consisting of the
          following members to redress the Grievances of the students in the
          institute for the academic session 2024-25 for UG/PG programme.
        </p>
        <ol>
          <li>Dr. Debabrata Rath (Principal, NIET, Chhatabar, Chairman)</li>
          <li>
            Mr. Mrutyunjay Senapati (Vice-Principal, NIET, Chhatabar, Member)
          </li>
          <li>
            Mr. Pragati Priya Ranjan Patra (Lect. Dept. of Computer Science &
            Engg., Member)
          </li>
          <li>Ms. Suprava Samal (Lect. Dept. of Civil Engg., Member)</li>
          <li>
            Mr. Nihar Ranjan Panda (HOD, Computer Science & Engg., Member)
          </li>
          <li>Mrs. Sasmita Mishra (Librarian, NIET, Chhatabar, Member)</li>
          <li>
            Mr. Pratishruti Pattnaik (B. TECH, 5th Semester, CSE, Member
            (student))
          </li>
          <li>
            Mr. Ajatrishna Pradhan (B. TECH, 5th Semester, CIVIL, Member
            (student))
          </li>
          <li>Mr. Satyabrata Dwari (1st Semester, MCA, Member (student))</li>
          <li>Ms. Surabhi S Sahoo (1st Semester, MCA, Member (student))</li>
          <li>Ms. Anupama Mallick (Diploma 3rd Sem, EE, Member (student))</li>
          <li>Mr. Pravakar Bhoi (Diploma 3rd Sem, MECH, Member (student))</li>
        </ol>
      </PageSection>
    </div>
  );
};

export default AntiRagingResponsive;
