import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { publicationData } from "../../static-data/publication.data";
import { Table } from "react-bootstrap";
import { iqacFacultyData } from "../../static-data/iqacFacultyData";

const ResearchContentResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  const descriptionClasses = classNames("mb-4 text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  return (
    <PageSection>
      <h4 className="">1. Research and Development Cell:</h4>
      <p className={descriptionClasses}>
        To foster a dynamic research environment that promotes innovation and
        collaboration among faculty, students, and industry partners. The cell
        aims to drive cutting-edge research and consultancy projects,
        facilitating foreign collaborations and industry partnerships that
        enhance knowledge transfer and address real-world challenges in the
        fields of electrical engineering and renewable energy technologies. By
        creating opportunities for interdisciplinary projects and skill
        development, the cell seeks to contribute to sustainable technological
        advancements and the overall growth of the institution.
        <p className="mt-4">Members:</p>
        <div className="ml-5 mt-4">
          <ol type="a">
            <li>
              <p>Dr. Debabrata Rath (chairman)</p>
            </li>
            <li>
              <p>Dr.Jayanta Kumar Sahu (Member)</p>
            </li>
            <li>
              <p>Dr.Manoj Kumar Biswal (Member)</p>
            </li>
          </ol>
        </div>
      </p>

      <h4 className="">2. Entrepreneurship Cell :</h4>
      <p className={descriptionClasses}>
        To cultivate a vibrant entrepreneurial ecosystem within the institution
        that empowers students and faculty to transform innovative ideas into
        viable business ventures. The Entrepreneurship Cell aims to provide
        resources, mentor-ship, and networking opportunities to aspiring
        entrepreneurs, fostering a culture of innovation and self-reliance. By
        facilitating workshops, seminars, and industry collaborations, the cell
        seeks to enhance entrepreneurial skills and support the development of
        startups, contributing to economic growth and job creation in the
        community.
        <p className="mt-4">Members:</p>
        <div className="ml-5 mt-4">
          <ol type="a">
            <li>
              <p>Dr. Debabrata Rath (chairman)</p>
            </li>
            <li>
              <p>Dr.Jayanta Kumar Sahu (Member)</p>
            </li>
            <li>
              <p>Dr.Manoj Kumar Biswal (Member)</p>
            </li>
          </ol>
        </div>
      </p>

      <h4 className="">3. Central Facilities :</h4>
      <p className={descriptionClasses}>
        To establish a comprehensive Central Facility dedicated to civil
        engineering materials and soil testing, providing high-quality testing
        and analysis services to support research, education, and industry
        applications. The facility aims to equip students, faculty, and industry
        professionals with access to advanced testing equipment and expertise in
        material characterization and soil analysis. By promoting collaboration
        and innovation in civil engineering, the Central Facilities will enhance
        the understanding of material properties and soil behavior, ultimately
        contributing to the development of safer and more sustainable
        construction practices.
      </p>

      <h4 className="">4. Research Laboratories :</h4>
      <p className={descriptionClasses}>
        To establish a collaborative research environment equipped with advanced
        tools and technologies for software development and data analysis. The
        Research Laboratories aim to provide access to programming languages and
        frameworks such as C, C++, Java, Python, as well as software platforms
        like MATLAB and Multisim. By fostering hands-on learning and research in
        areas such as data structures, algorithm design, and simulation, the
        laboratories seek to enhance students' and researchers' technical
        skills. This initiative aims to promote innovation and practical
        applications in engineering and computer science, ultimately
        contributing to the advancement of knowledge and technology in the
        field.
      </p>

      <h4 className="">5. Thrust Areas:</h4>
      <p className={descriptionClasses}>
        <div className="ml-5 mt-4">
          <ul>
            <li>
              <p>Data science</p>
            </li>
            <li>
              <p>Machine learning</p>
            </li>
            <li>
              <p>Artificial Intelligence</p>
            </li>
            <li>
              <p>Renewable Energy system</p>
            </li>
            <li>
              <p>Control of Photo Voltaic System and MPPT Algorithm</p>
            </li>
            <li>
              <p>VLSI</p>
            </li>
            <li>
              <p>IOT</p>
            </li>
          </ul>
        </div>
      </p>
    </PageSection>
  );
};

export default ResearchContentResponsive;
