import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import CustomIcon from "../CustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";
import CoursesMiniCard from "../CoursesMiniCard/CoursesMiniCard";
import { coursesData } from "../../static-data/courses.data";

const CoursesResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const rowContainerClasses = classNames({
    "w-75 mx-auto": !(
      currentBreakPoint === "xSmall" || currentBreakPoint === "small"
    ),
  });

  return (
    <PageSection type="lg" bgClass="page-background-1">
      <ResponsivePageHeader title="Explore Our Courses" />
      <div>
        <Row className="gx-1">
          {coursesData.map((data, index) => (
            <Col
              md="4"
              className="d-flex align-items-stretch mb-4 "
              key={"course-responsive" + index}
            >
              <CoursesMiniCard {...data} />
            </Col>
          ))}
        </Row>
      </div>

      <br />

      <ResponsivePageHeader centered={true} title="Essential Documents" />

      <Row className="justify-content-center">
        <Col md="4">
          <a
            href={process.env.PUBLIC_URL + "/MANDATORY DISCLOUSER NIET.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">MANDATORY DISCLOUSER 2024-25</span>
          </a>
        </Col>
        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL +
              "/NIET ACTS or STATUTES for EXAMINATION ACADEMICS REVISED.pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              NIET ACTS or STATUTES for EXAMINATION ACADEMICS REVISED
            </span>
          </a>
        </Col>
        <Col md="4">
          <a
            href={process.env.PUBLIC_URL + "/ADMISSION PROCESS & GUIDLINES.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">ADMISSION PROCESS & GUIDLINES</span>
          </a>
        </Col>

        <br />
        <br />
        <br />

        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL +
              "/DETAILS OF ACADEMIC PROGRAMME (c-1).docm"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">DETAILS OF ACADEMIC PROGRAMME (c-1)</span>
          </a>
        </Col>
        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL +
              "/DETAILS OF ACADEMIC PROGRAMM 2024-25.docx"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">DETAILS OF ACADEMIC PROGRAMM 2024-25</span>
          </a>
        </Col>
        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL +
              "/NIET ACTS or STATUTES for EXAMINATION ACADEMICS (c-3).pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              NIET ACTS or STATUTES for EXAMINATION ACADEMICS (c-3)
            </span>
          </a>
        </Col>
        <Col md="4">
          <a
            href={process.env.PUBLIC_URL + "/FEE REFUND POLICY.jpg"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">FEE REFUND POLICY</span>
          </a>
        </Col>

        <br />
        <br />
        <br />

        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL +
              "/NIET Academic Calender 2023 - 2024 (MCA).pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              NIET Academic Calender 2023 - 2024 (MCA)
            </span>
          </a>
        </Col>
        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL +
              "/NIET Academic Calender 2023 - 2024 (B.Tech).pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              NIET Academic Calender 2023 - 2024 (B.Tech)
            </span>
          </a>
        </Col>
        <Col md="4">
          <a
            href={
              process.env.PUBLIC_URL +
              "/NIET Academic Calender 2023 - 2024 (Diploma).pdf"
            }
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              NIET Academic Calender 2023 - 2024 (Diploma)
            </span>
          </a>
        </Col>
      </Row>
    </PageSection>
  );
};

export default CoursesResponsive;
