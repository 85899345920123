import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const IQACResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const descriptionClasses = classNames("mb-4 text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="IQAC" />
      <p className={descriptionClasses}>
        The Internal Quality Assurance Cell (IQAC) at NIET is committed to
        enhancing the quality of education and fostering a culture of continuous
        improvement within the institution. The IQAC focuses on:
      </p>

      <div className="ml-2">
        <ul>
          <li>
            <p>
              Implementing systematic quality assurance measures across all
              academic and administrative processes.
            </p>
          </li>
          <li>
            <p>
              Promoting a culture of quality among faculty, staff, and students.
            </p>
          </li>
          <li>
            <p>
              Encouraging innovative teaching methodologies to enhance student
              engagement and learning outcomes.
            </p>
          </li>
          <li>
            <p>
              Collaborating with stakeholders to implement best practices and
              align with national and international standards.
            </p>
          </li>
        </ul>
      </div>
      
      
    </PageSection>
  );
};

export default IQACResponsive;
