import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Table } from "react-bootstrap";
import {
  bTechProgramData,
  diplomaProgramData,
  pgProgramData,
} from "../../static-data/programs.data";

const CourseInfoResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="Programs" />
      <p className={descriptionClasses}>
        At NIET, we offer a wide range of academic programs designed to equip
        our students with the knowledge and skills they need to succeed in their
        chosen careers. We offer undergraduate and diploma programs in various
        engineering disciplines, including:
      </p>

      <br />

      <h3 className={descriptionClasses}>Diploma</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th>Program</th>
            <th>Intake</th>
          </tr>
        </thead>
        <tbody>
          {diplomaProgramData.map((val) => {
            return (
              <tr>
                <td>{val.SlNo}</td>
                <td>{val.Program}</td>
                <td>{val.Intake}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <br />

      <h3 className={descriptionClasses}>Under Graduate</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th>Program</th>
            <th>Intake</th>
          </tr>
        </thead>
        <tbody>
          {bTechProgramData.map((val) => {
            return (
              <tr>
                <td>{val.SlNo}</td>
                <td>{val.Program}</td>
                <td>{val.Intake}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <br />

      <h3 className={descriptionClasses}>Post Graduate</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Sl. No.</th>
            <th>Program</th>
            <th>Intake</th>
          </tr>
        </thead>
        <tbody>
          {pgProgramData.map((val) => {
            return (
              <tr>
                <td>{val.SlNo}</td>
                <td>{val.Program}</td>
                <td>{val.Intake}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      {/* <a
				href={process.env.PUBLIC_URL + "/RESEARCHPOLICY.docx"}
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">DOWNLOAD RESEARCH POLICY</span>
			</a> */}
    </PageSection>
  );
};

export default CourseInfoResponsive;
