import React from "react";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import HeroSection from "../../../components/HeroSection/HeroSection";
import HeroCaption from "../../../components/HeroCaption/HeroCaption";

import FacultyResponsive from "../../../components/FacultyResponsive/FacultyResponsive";
import CoursesResponsive from "../../../components/CoursesResponsive/CoursesResponsive";
import DepartmentInfoResponsive from "../../../components/DepartmentInfoResponsive/DepartmentInfoResponsive";
import DepartmentAboutInfoResponsive from "../../../components/DepartmentAboutInfoResponsive/DepartmentAboutInfoResponsive";
import HODResponsive from "../../../components/HODResponsive/HODResponsive";

import { mechanicalLessonPlan } from "../../../static-data/courselessonplan.data";
import { mechanicalMaterials } from "../../../static-data/coursematerial.data";
import ResourcesResponsive from "../../../components/ResourcesResponsive/ResourcesResponsive";

const hodData = {
  imageUrl: require("../../../assets/hod-images/mech.png"),
  designation: "Head of the Department",
  name: "Miss. Preeti Sahoo",
  feedback: `Dear Students,
	As the Head of the Mechanical Engineering Department at NIET, I am pleased to welcome you to our department. Our department is dedicated to providing you with a comprehensive education in mechanical engineering that prepares you for a successful career in the industry.
	Our faculty members are experts in their respective fields and are committed to providing you with a challenging and rigorous curriculum that covers a wide range of topics, including mechanics, thermodynamics, manufacturing, design, and robotics.
	Our department is equipped with state-of-the-art facilities, including mechanical labs, simulation tools, and equipment, to support your practical learning experience. We also provide opportunities for you to work on projects and participate in research programs to gain hands-on experience.
	We encourage you to participate in co-curricular activities such as technical paper presentations, project exhibitions, and industry collaborations, which provide you with opportunities to showcase your skills and talents and network with industry professionals.
	Our goal at NIET's Mechanical Engineering Department is to produce graduates who are well-prepared to contribute to the industry and society as a whole. We are committed to providing you with the best possible education in mechanical engineering and equipping you with the skills you need to succeed in your chosen career paths.
	I look forward to working with all of you and wish you the best of luck in your academic pursuits.
	
    `,
};

const mechanicalSyllabus =
  "https://drive.google.com/drive/folders/1zx0kwp6RwkaRfXfExGVTWO7iOAGN-NTi?usp=drive_link";

const MechanicalPage = () => {
  return (
    <PageWrapper className="mt-n6">
      <HeroSection className="bg-page-background-7">
        <HeroCaption
          title="Mechanical Engineering"
          position="bottom-left"
          breadcrumb={true}
          breadcrumbPrefix="Home"
        />
      </HeroSection>
      <DepartmentInfoResponsive
        title={"Mechanical"}
        desc={`The Mechanical Engineering Department at NIET offers a comprehensive education in the field of mechanical engineering. Our department is dedicated to providing students with the skills and knowledge they need to succeed in the industry and make valuable contributions to society.

`}
      />
      <HODResponsive hodData={hodData} />
      <DepartmentAboutInfoResponsive
        title={"About department"}
        desc={`The Mechanical Engineering Department at NIET offers a comprehensive education in the field of mechanical engineering. Our department is dedicated to providing students with the skills and knowledge they need to succeed in the industry and make valuable contributions to society.
				Our faculty members are experts in their respective fields and are committed to delivering a challenging and rigorous curriculum that covers a wide range of topics, including mechanics, thermodynamics, manufacturing, design, and robotics. We offer a B.Tech in Mechanical Engineering program that covers both core and elective courses, allowing students to specialize in their area of interest.
				Our department is equipped with state-of-the-art facilities, including mechanical labs, simulation tools, and equipment, to support students' practical learning experience. We also provide opportunities for students to work on projects and participate in research programs to gain hands-on experience.
				We encourage students to participate in co-curricular activities such as technical paper presentations, project exhibitions, and industry collaborations, which provide them with opportunities to showcase their skills and talents and network with industry professionals.
				Our goal at NIET's Mechanical Engineering Department is to produce graduates who are well-prepared to contribute to the industry and society as a whole. We are committed to providing our students with the best possible education in mechanical engineering and equipping them with the skills they need to succeed in their chosen career paths.`}
      />
      <ResourcesResponsive
        syllabusLink={mechanicalSyllabus}
        courseLessonPlan={mechanicalLessonPlan}
        courseMaterial={mechanicalMaterials}
      />
    </PageWrapper>
  );
};

export default MechanicalPage;
